.mobileApp {
    position: fixed;
    height: 100%;
}
.mobileContainer {
    height: auto;
    font-size: calc(10px + 0.8vmin);
    color: black;
    background-color: #FAFAFA;
}

.header {
    display: flex;
}

.header1 {
}


.headBox {
    display: flex;
    width: 4vw;
    flex-direction: row;
    align-items: flex-start;
    justify-content: space-between;
    margin: 5px;
}

.burger {
    background-color: äFAFAFA;
    border: 0px solid white;
    padding-top: 0.2vh;
    padding-left: 0vw;
    padding-right: 1vw;
}

.mobileHeader {
    position: absolute;
    top: 0;
    z-index: 300;
    overflow: hidden;
    /*background-color: white;*/
}

.mobileheadBox {
    display: flex;
    width: 100vw;
    height: 5vh;
    flex-direction: row;
    align-items: flex-start;
    justify-content: space-between;
}

.headTitle {
    font-size: larger;
    color: #999999;
    font-weight: 400;
    padding-left: 5vw;
    padding-top: 2vh;
}


.mobileBurger {
    width: 10vw;
    padding-top: 3vw;
    padding-right: 5vw;
}

.burgerMenu {
    position: absolute;
    width: 20vw;
    top: 1vh;
    left: 2.5vw;
    z-index: 101;
    color: black;
    background-color: #ebebeb;
    margin: 1vmin;
}

.list {
    padding: 1vh 2vw 1vh 1vw;
}

.burgerItem {
    /*font-size: medium;*/
    /*font: inherit;*/
    padding: 1vh 2vw 1vh 1vw;
    border-bottom: 1px solid lightgray;
}

.burgerItem:hover {
    color: white;
    background-color: #444444;
}

.burgerItemLast {
    /*font-size: medium;*/
    padding: 1vh 2vw 1vh 1vw;
    /*border-bottom: 1px solid lightgray;*/
}

.burgerItemLast:hover {
    color: white;
    background-color: #444444;
}


.mobileBurgerMenu {
    position: absolute;
    width: 50vw;
    top: 5vh;
    right: 6vw;
    z-index: 101;
    color: black;
    background-color: #ebebeb;
    margin: 1vmin;
}

.mobileList {
    padding: 1vh 2vw 1vh 2vw;
}


.mobileBurgerItem {
    /*font-size: medium;*/
    padding: 1vh 2vw 1vh 3vw;
    border-bottom: 1px solid lightgray;
}

.mobileBurgerItemLast {
    /*font-size: medium;*/
    padding: 1vh 2vw 0.5vh 3vw;
    /*border-bottom: 1px solid lightgray;*/
}


.mobileFooter {
    position: absolute;
    bottom: 0vh;
    z-index: 100;
}


.mobiletitle {
    /*color: #2E75B6;*/
    font-size: larger;
    margin: 1vh 5vw 0 5vw;
}

.mobilesubtitle {
    color: #A6A6A6;
    font-size: small;
    margin: 1vh 5vw 0 5vw;
}

.mobileTreeContainer {
    position: relative;
    top: 6vh;
    background-color: inherit;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: left;
}

.mobiletree {
    position: relative;
    /*top: 10%;*/
    left:0;
    /*margin-top: 5%;*/
    /*border: 1px solid green;*/
    box-sizing: border-box;
    align-self: center;
    justify-self: center;
    width: 100vw;
}

.mobiletreeSVG {
    /*position: relative;*/
    /*top: 10%;*/
    /*left:0;*/
    /*margin-top: 5vh;*/
    /*border: 1px solid green;*/
    /*box-sizing: border-box;*/
    /*align-self: center;*/
    /*justify-self: center;*/
    /*width: 100vw;*/
}



.moCircle {
    position: absolute;
    left: 0;
    width: 100vw;
    bottom: 0;
}

.moCircle1 {
    position: absolute;
    left: 0;
    width: 100vw;
    bottom: 0;
}

.moCircle2 {
    position: absolute;
    left: 0;
    width: 100vw;
    bottom: 0;
}


.fieldIcon1 {
    position: absolute;
    left: 2vw;
    bottom: 9vh;
    width: 14vw;
}

.fieldIcon2 {
    position: absolute;
    left: 23vw;
    bottom: 11vh;
    width: 10vw;
}
.fieldIcon3 {
    position: absolute;
    left: 42vw;
    bottom: 10vh;
    width: 12vw;
}
.fieldIcon4 {
    position: absolute;
    left: 63vw;
    bottom: 12vh;
    width: 10vw;
}
.fieldIcon5 {
    position: absolute;
    left: 80vw;
    bottom: 9vh;
    width: 16vw;
}


.guidelineIcon1 {
    position: absolute;
    left: 9vw;
    bottom: 10vh;
    width: 10.5vw;
}

.guidelineIcon2 {
    position: absolute;
    left: 32vw;
    bottom: 11vh;
    width: 9vw;
}
.guidelineIcon3 {
    position: absolute;
    left: 55vw;
    bottom: 14vh;
    width: 11vw;
}
.guidelineIcon4 {
    position: absolute;
    left: 80vw;
    bottom: 12vh;
    width: 11vw;
}

.focusIcon1{
    position: absolute;
    left: 6vw;
    bottom: 9vh;
    width: 27vw;
}

.focusIcon2{
    position: absolute;
    left: 36vw;
    bottom: 9vh;
    width: 27vw;
}

.focusIcon3{
    position: absolute;
    left: 69vw;
    bottom: 9vh;
    width: 27vw;
}


.backButton {
    position: absolute;
    left: 40vw;
    bottom: 0vh;
    width: 19vw;
}

/*.nextButton {*/
/*    position: absolute;*/
/*    left: 85vw;*/
/*    bottom: 1vh;*/
/*    width: 12vw;*/
/*}*/

.nextButton {
    position: absolute;
    left: 81vw;
    bottom: 2vh;
    width: 15vw;
    font-size: large;
}

.mobileTextContainer {
    background-color: inherit;
    position: absolute;
    top: 7vh;
    background-color: inherit;
    z-index: 10;
    margin: 0px 0px 0px 10px;
}

.content {
    padding: 0px 15px 0px 10px;
    height: 72vh;
    overflow: auto;
    overflow-x: hidden;
}

.contentIntro {
    padding: 0px 15px 0px 10px;
    height: 85vh;
    overflow: auto;
    overflow-x: hidden;
}


::-webkit-scrollbar {
    height: 6px; /* At least not zero */
}

::-webkit-scrollbar-thumb {
    background-color: rgba(0, 0, 0, .1); /* Or any other instruction making the element visible */
}

.HeadingContainer {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    width: 95%;
    justify-content: space-between;
    z-index: 10;
}

.mobileheadings {
    flex-grow: 5;
    flex-shrink: 5;
}

.mobileicon {
    flex-grow: 1;
    flex-shrink: 1;
    /*padding-top: 5px;*/
    /*align-self: start;*/
    align-self: flex-start;
    margin-left: 10px;
    /*width:80px*/
    /*height: 60%;*/
}


.heading {
    font-size: calc(10px + 0.9vmin);;
    /*font-weight: bolder;*/
    padding-bottom: 10px;
}

.subheading {
    padding-top: 10px;
}

.descContainer {
    background-color: inherit;
    width: 100%;
    /*overflow-y: visible;*/
}

.descHeader {
    padding-top: 10px;
    padding-bottom: 5px;
    background-color: inherit;
    width: 100%;
}

.descText1 {
    /*text-indent: 0.01px;*/
    /*text-indent: 0px !important;*/
    font-size: 95%;
    /*margin: 0px 0px 5px 0px;*/
    /*padding: 5px 10px 5px 10px;*/
    /*border: 1px solid lightgray;*/
    background-color: inherit;
    width: 100%;
}

/*.descPar {*/
/*    !*overflow: hidden;*!*/
/*    padding-bottom: 5px;*/
/*    background-color: inherit;*/
/*    width: 100%;*/
/*}*/

.normdescHeader {
    /*font-weight: 500;*/
    padding-top: 10px;
    padding-bottom: 5px;
    background-color: inherit;
    width: 100%;
}

.normdescText {
    font-size: 95%;
    text-indent: 0px !important;
    padding: 0px;
    margin: 0px;
    /*margin: 0px 0px 5px 0px;*/
    /*padding: 5px 10px 5px 10px;*/
    /*border: 1px solid lightgray;*/
    background-color: inherit;
    width: 100%;
}

.normdescPar {
    /*overflow: hidden;*/
    padding-bottom: 10px;
    background-color: inherit;
    width: 100%;
}

.Infobox {
    /*background-color: #F4F5EF;*/
    background-color: #eaeae1;
    /*border: 1px solid #bbcdbb;*/
    margin: 10px 20px 20px 0px;
    padding: 10px 0px 10px 0px;
    width: 100%;
}
.InfoboxItem {
    display: flex;
    flex-direction: column;
    padding: 10px;
}
.InfoboxItemHeader {
    display: flex;
    align-items: baseline;
    flex-direction: row;
}

.bullet{
    padding-right: 15px;
}

.InfoboxItemTitle {
    font-weight: bold;
}

/*.InfoboxItemSubtext {*/
/*    font-size: 95%;*/
/*    padding-left: 25px;*/
/*    padding-right: 10px;*/
/*}*/

/*.InfoboxDesc {*/
/*    !*overflow: hidden;*!*/
/*    font-size: 95%;*/
/*    margin: 5px 10px 5px 25px;*/
/*    padding: 5px 15px 5px 15px;*/
/*    background-color: #eaeae1;*/
/*}*/

.link {
    color: #2E75B6;
    text-decoration: none;
    cursor: pointer;
}

.linkLess {
    padding-left: 0.1vw;
    color: #2E75B6;
    text-decoration: none;
    cursor: pointer;
}

.link:hover {
    text-decoration: underline;
}

.linkLess:hover {
    text-decoration: underline;
}

.downloadLine {
    display: flex;
    flex-direction: row;
}

.downloadIcon {
    width: 1.2em;
    padding-right: 0.5vw;
}

.mobileLogo100 {
    display: block;
    margin-left: auto;
    margin-right: auto;
    width: 30%;
}

@keyframes App-logo-spin {
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(360deg);
    }
}

/* experimental */

.descPar {
    text-align: justify;
    hyphens: auto;
}
