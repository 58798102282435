.dummy {
  position: fixed;
  width: 100%;
  height: 100%;
  pointer-events: none;
}

.dummyline {
  position: absolute;
  /*top: 623px;*/
  top: 706px;
  /*bottom: 16vh;*/
  left: 0px;
  height: 1px;
  width: 100vw;
  color: green;
  background-color: yellow;
  border: 2px solid red;
  z-index: 300000;
}

.dummyline2 {
  position: absolute;
  top: 767px;
  /*top: 84vh;*/
  /*bottom: 16vh;*/
  left: 0px;
  height: 1px;
  width: 100vw;
  color: blue;
  background-color: lightgreen;
  border: 2px solid blue;
  z-index: 300000;
}


button {
  font: inherit;
  border: 0px solid white;
  background-color: inherit;
}

.App {
  text-align: left;
  background-color: #FAFAFA;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.Container {
  /*background-color: #282c34;*/
  min-height: 100vh;
  height: auto;
  display: flex;
  flex-direction: row;
  align-items: center;
  /*justify-contentFields: left;*/
  font-size: calc(10px + 0.8vmin);
  color: black;
  background-color: #FAFAFA;
  /*background-color: white;*/
}


.TreeContainer {
  background-color: inherit;
  position: fixed;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: left;
  width: 47vw;
  height: 97vh;
  /*padding: 1vmin;*/
  margin-left: 1.5vw;
  border-right: 1px solid darkgray;
  /*padding-bottom: 5vw;*/
}



.tree {
  /*border: 2px solid green;*/
  box-sizing: border-box;
  align-self: center;
  justify-self: center;
  /*width: 80%;*/
  width: 38vw;
  max-height: 85vh;
  padding: 1vmax;
  /*margin-bottom: 5vh;*/
}


.treeSVG {
  /*outline: 0px;*/
}

.title {
  /*color: #2E75B6;*/
  color: #444444;
  font-size: larger;
  margin: 5px;
  margin-top: 8px;
}



.subtitle {
  color: #A6A6A6;
  font-size: small;
  margin: 0px 5px 15px 5px;

}

.TextContainer {
  background-color: inherit;
  position: absolute;
  left: 48vw;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: left;
  /*border-left: 1px solid black;*/
  width: 45vw;
  height: 96vh;
  /*height: fit-content;*/
  padding: 10px 30px 0px 30px;
  margin: 0px 10px 0px 20px;
  background-color: inherit;
}


.HeadingContainer {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  width: 95%;
  justify-content: space-between;
}

.headings {
  flex-grow: 15;
  /*flex-shrink: 15;*/
}

.icon {
  align-self: start;
  flex-grow: 1;
  flex-shrink: 1;
  margin-left: 1vw;
  margin-bottom: 1px;
  /*height: 10vh;*/
  /*height: 50%;*/
  /*border: 1px solid green;*/
}

.heading {
  /*color: #555555;*/
  font-size: large;
  /*font-weight: 500;*/
  font-weight: 700;
  padding-bottom: 10px;
}



.heading1cont { 
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
}
.heading1 {
  /*color: #555555;*/
  font-size: calc(10px + 0.9vmin);
  /*font-weight: 500;*/
  font-weight: 700;
  padding-bottom: 10px;
}
.logo1{
  align-self: end;
}


.subheading {
  padding-left: 5px;
  padding-top: 10px;
  font-weight: 400;
  font-style: italic;
}

.descContainer {
  background-color: inherit;
  width: 100%;
}

.descHeader {
  /*padding-top: 10px;*/
  padding-left: 5px;
  font-weight: 500;
  padding-bottom: 5px;
  background-color: inherit;
  width: 100%;
}

.descText {
  font-size: 95%;
  text-indent: 0 !important;
  margin: 0px 0px 15px 0px;
  padding: 0px 0px 0px 0px;
  /*border: 1px solid lightgray;*/
  background-color: inherit;
  width: 100%;
}

.descText ul {
  list-style-type: none; /* Remove bullets */
  /*padding: 5px; */
  margin-left: 1vw;
}
.descText li {
  margin:5px;
}

.descText a {
  color: #226fb9;
  cursor: pointer;
}

.descText a:hover {
  font-weight: bold;
  text-decoration: underline;
}


.descPar {
  text-indent: 0 !important;
  /*overflow: hidden;*/
  padding-bottom: 5px;
  background-color: inherit;
  width: 100%;
  margin-right: 10px;
  /*text-align: justify*/
  /*hyphens: auto;*/
}

.Infobox {
  /*background-color: #F4F5EF;*/
  background-color: #eaeae1;
  /*border: 1px solid #bbcdbb;*/
  margin: 10px 20px 20px 0px;
  padding: 10px 0px 10px 0px;
  width: 95%;
}
.InfoboxItem {
  display: flex;
  flex-direction: column;
  padding: 10px;
}
.InfoboxItemHeader {
  color: #111111;
  display: flex;
  align-items: baseline;
  flex-direction: row;
  margin-bottom: 10px;
}

.bullet{
  padding-right: 15px;
}

.InfoboxItemTitle {
  font-weight: normal !important;
}

.InfoboxItemSubtext {
  font-size: 100%;
  padding-left: 25px;
  padding-right: 10px;
}

.InfoboxDesc {
  /*overflow: hidden;*/
  text-indent: 0;
  font-size: 95%;
  margin: 0px 20px 0px 20px;
  /*padding: 0px 0px 0px 15px;*/
  background-color: #eaeae1;
}

.link {
  font-size: 100%;
  color: #2E75B6;
  text-decoration: none;
  cursor: pointer;
}

.link:hover {
  text-decoration: underline;
}

.introcontainer {
  display: flex;
  flex-direction: column;
  flex: 0 0 auto;
}

.introHeader {
  margin-top: 3.1vh;
  font-size: 1.6em;
}

.introHeader2 {
  font-size: 1.8em;
}

.introSubhead {
  display: flex;
  flex-direction: row;
  margin-top: 6vh;
  margin-bottom: 6vh;
  font-size: 1.1em;
  flex-wrap: nowrap;
  /*align-items: flex-start;*/
  /*justify-content: left;*/
  align-items: center;
}

.introText {
  margin-left: 1.8vw;
  font-size: 1.2em;
}

.introArrow {
  height: 2.1em;
}


@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

@media only screen and (orientation: landscape) and (max-height: 600px ){
  .TreeContainer {
    /*background-color: lightblue;*/
    /*height: 90vh;*/
  }
  .tree {
    max-height: 79vh;
    justify-self: start;
    padding: 0;
  }

  .title {
    font-size: small;
  }
  .subtitle {
    margin-bottom: 0;
  }
  .TextContainer {
    width: unset;
  }
}
