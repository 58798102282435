@font-face {
  font-family: 'Abel-Regular';
  src: url('/public/abelfonts/fonts/Abel-Regular.eot'); /* IE9 Compat Modes */
  src: url('/public/abelfonts/fonts/Abel-Regular.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
  url('/public/abelfonts/fonts/Abel-Regular.woff2') format('woff2'), /* Super Modern Browsers */
  url('/public/abelfonts/fonts/Abel-Regular.woff') format('woff'), /* Pretty Modern Browsers */
  url('/public/abelfonts/fonts/Abel-Regular.ttf')  format('truetype'), /* Safari, Android, iOS */
  url('/public/abelfonts/fonts/Abel-Regular.svg#svgFontName') format('svg'); /* Legacy iOS */
  font-display: swap; /* this is important for safari */
  font-weight: normal; /* this is important for safari */
  font-style: normal; /* this is important for safari */
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #FAFAFA;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
